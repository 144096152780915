import {get, post, download} from "./index.js";


/**
 * 进项发票预勾选抵扣（分页)
 */
 export const getPreIncomeVATInvoicesPage = (param) => {
    return get("/income-invoice/pre-income/page", param).then(res => res);
}

/**
 * 预勾选发票导出
 * @param data
 * @returns {Promise<unknown>}
 */
 export const downloadDeductExcel = data => {
    return download("/deduct/income-invoice/deductExport", data).then(res => res);
}

/**
 * 进项发票预勾选抵扣统计
 * @param data
 * @returns {Promise<unknown>}
 */
 export const getIncomeInvoicesStatistics = data => {
    return post("/income-invoice/pre-income/summary", data).then(res => res);
}

/**
 * 提交发票预勾选
 * @param data
 * @returns {Promise<unknown>}
 */
 export const updatePreCheckStatus = data => {
    return post("/income-invoice/updatePreCheckStatus", data).then(res => res);
}

/**
 * 生成抵扣表 
 * @param data
 * @returns {Promise<unknown>}
 */
 export const updateSignPeriod = data => {
    return post("/income-invoice/updateSignPeriod", data).then(res => res);
}

/**
 * 进项发票客运服务抵扣（分页）
 */
 export const getUnauthenticatedPassengerPage = (param) => {
    return get("/income-invoice/unauthenticatedPassenger/page/", param).then(res => res);
}

/**
 * 进项发票客运服务抵扣统计
 * @param data
 * @returns {Promise<unknown>}
 */
 export const getPassengerIncomeInvoicesStatistics = data => {
    return post("/income-invoice/unauthenticatedPassenger/summary", data).then(res => res);
}
